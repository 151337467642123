import { ConfigMurucaAdvancedSearchLayout } from '@net7/boilerplate-muruca';

const config: ConfigMurucaAdvancedSearchLayout = {
  title: 'Búsqueda Avanzada',
  resultsUrl: '/resultados',
  hasDynamicOptions: true,
  formConfig: {
    submitButton: {
      label: 'advancedsearch#submit',
    },
    resetButton: {
      label: 'advancedsearch#reset',
    },
    groups: [
      {
        id: 'group-1',
        sections: ['section-1'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_global',
          isOpen: true,
          showHeader: false,
        },
      },
    ],
    sections: [
      {
        id: 'section-1',
        title: '',
        inputs: [
          {
            id: 'query-fulltext',
            type: 'text',
            data: {
              id: 'query-fulltext',
              label: 'advancedsearch#fulltext_text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-title',
            type: 'text',
            data: {
              id: 'query-title',
              label: 'advancedsearch#label_title',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'work_author',
            type: 'select',
            data: {
              id: 'work_author',
              label: 'advancedsearch#label_author',
              options: []

            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-date',
            type: 'text',
            data: {
              id: 'query-date',
              label: 'advancedsearch#label_date',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-edition',
            type: 'text',
            data: {
              id: 'query-edition',
              label: 'advancedsearch#label_edition',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-bibl',
            type: 'text',
            data: {
              id: 'query-bibl',
              label: 'advancedsearch#label_bibliography',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
    ],
  },
};

export default config;
