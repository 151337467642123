export default {
  'global#home': 'Inicio',
  'global#works': 'Obras',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Spiacenti, si è verificato un errore',
  'global#layout_error_description': 'Prova a ricaricare la pagina tra qualche istante e se il problema persiste contatta l\'amministratore della piattaforma.',
  'global#facet_empty_text': 'No hay resultados disponibles',
  'readmore#label': 'Mostrar todo',
  'search#title_works': 'Obras',
  'search#title_bibliographies': 'Bibliografías',
  'advancedsearch#fulltext_text': 'Buscar en el portal (en todos los campos)',
  'advancedsearch#section_global': 'Busca en el portal',
  'advancedsearch#label_title': 'Título',
  'advancedsearch#label_manuscript_content': '',
  'advancedsearch#reset': 'Limpiar los campos',
  'advancedsearch#submit': 'Buscar',
  'advancedsearch#label_bibliography': 'Bibliografía',
  'search#maps_total': '<strong>{ total }</strong> Mapas',
  'search#maps_total_1': '<strong>{ total }</strong> Mapa',
  'search#maps_total_0': 'Sin mapa',
  'search#bibliographies_total': '<strong>{ total }</strong> Bibliografías',
  'search#bibliographies_total_1': '<strong>{ total }</strong> Bibliografía',
  'search#bibliographies_total_0': 'Sin bibliografía',
  'search#works_total': '<strong>{ total }</strong> Obras',
  'search#works_total_1': '<strong>{ total }</strong> Obra',
  'old_editions.edition': 'Ediciones antiguas',
  'modern_editions.edition': 'Ediciones modernas',
  'advancedsearch#label_author': 'Autor',
  'author.name': 'Autor',
  'advancedsearch#label_edition': 'Ediciones',
  'advancedsearch#label_date': 'Fecha',
  'advancedsearch#label_synopsis': 'Sinopsis',
  'advancedsearch#label_note': 'Anotaciones',
  'advancedsearch#label_source': 'Fuentes caballerescas',
  'advancedsearch#filters_title': ' ',
  'search#results_number': 'Número de resultados',
  'search#works_total_0': 'Sin obra',
  'search#placeholder_date': 'Buscar en años',
  'search#placeholder_query': 'Buscar en títulos ',
  'search#placeholder_types': 'Buscar en tipologías',
  'search#placeholder_authors': 'Buscar en autores',
  'search#header_types': 'Tipologías',
  'search#header_sources': 'Fuentes',
  'search#header_projects': 'Proyectos',
  'search#header_languages': 'Idiomas',
  'search#header_romances': 'Ciclos',
  'search#header_subjects': 'Temas',
  'search#header_collocations': 'Localizaciónes',
  'search#header_authors': 'Autores',
  'search#header_dates': 'Años',
  'search#header_translators': 'Traductor',
  'search#title': 'Obras',
  'search#facets_title': 'Filtro de resultados',
  'search#filters_title': 'Filtros activos:',
  'search#sort_title': 'Ordenar',
  'search#sort_score': 'Orden por relevancia',
  'search#sort_asc': 'Orden alfabético (A→Z)',
  'search#sort_desc': 'Orden alfabético (Z→A)',
  'search#sort_date_asc': 'Orden por fecha (ASC)',
  'search#sort_date_desc': 'Orden por fecha (DESC)',
  'search#sort_label_ciclo': 'Orden por posición en el ciclo',
  'search#fallback_text': 'La búsqueda no ha devuelto ningún resultado. Intente cambiar los parámetros.',
  'search#fallback_button': 'Cancelar la búsqueda',
  'search#ko_text': 'La búsqueda no ha devuelto ningún resultado. Intente cambiar los parámetros.',
  'search#ko_button': 'Cancelar la búsqueda',
  'search#resultsamount': 'Resultados por página',
  'resource#authors': 'de',
  'resource#date': 'publicado en',
  'resource#time_to_read': 'lectura de',
  'resource#metadata': 'metadatos',
  'resource#collection-rewritings': 'Reescrituras',
  'resource#metadata_size': 'Dimensioni',
  'resource#page_bibliographic_data': 'Obra > Datos bibliográficos',
  'resource#page_codicological_data': 'Obra > Datos codicológicos',
  'resource#page_composition_process': 'Obra > Proceso de composición',
  'resource#page_cited_bibliography': 'Obra > Bibliografía citada',
  'resource#collection-translations': 'Traducciones relacionadas',
  'resource#collection-sources': 'Fuentes relacionadas',
  'resource#related_records': 'Fuentes relacionadas',
  'tabs#work_bibliographic_data': 'Datos bibliográficos',
  'tabs#work_codicological_data': 'Datos codicológicos',
  'tabs#work_composition_process': 'Proceso de composición',
  'tabs#work_cited_bibliography': 'Bibliografía citada',
  'readmore#more': 'Leer más',
  'readmore#less': 'Leer menos'
};
