/* eslint-disable @typescript-eslint/camelcase */
import homeConfig from './home.config';
import searchWorksConfig from './search-works.config';
import searchRecordsConfig from './search-records.config';
import resourceWorkConfig from './resource-work.config';
import resourceRecordConfig from './resource-record.config';
import advancedSearchConfig from './advanced-search.config';
import advancedResultsConfig from './advanced-results.config';
import pageConfig from './page.config';
import postConfig from './post.config';

export default {
  home: homeConfig,
  'advanced-search': advancedSearchConfig,
  'advanced-results': advancedResultsConfig,
  'search-works': searchWorksConfig,
  'search-records': searchRecordsConfig,
  'resource-work': resourceWorkConfig,
  'resource-record': resourceRecordConfig,
  page: pageConfig,
  post: postConfig,
};
