export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Fuente',
  type: 'bibliography/bibl_record',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
    ],
    content: [
      {
        id: 'metadata',
        type: 'metadata',
        title: 'resource#metadata'
      },
      {
        id: 'collection-sources',
        type: 'collection',
        grid: 3,
        title: 'resource#collection-sources',
      },
      {
        id: 'collection-translations',
        type: 'collection',
        grid: 3,
        title: 'resource#collection-translations',
      },
      {
        id: 'collection-rewritings',
        type: 'collection',
        grid: 1,
        title: 'resource#collection-rewritings',
        options: {
          linkTarget: '_blank'
        }
      }
    ],
  },
};
