/* es-lint disabled */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  RouterModule,
  Router,
  NavigationStart,
  RoutesRecognized,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { translate } from '@net7/core';
import {
  N7BoilerplateCommonModule,
  LocalConfigService,
  MainStateService,
  ConfigurationService,
  JsonConfigService,
} from '@net7/boilerplate-common';
import {
  N7BoilerplateMurucaModule,
  MrMenuService,
  MrFooterService,
  MrTranslationsLoaderService,
} from '@net7/boilerplate-muruca';
import { APP_ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import configMuruca from './config';
import i18n from './config/i18n';

const LANG_CODE = 'es_ES';

const JSON_PATH = './assets/app-config.local.json';

// load translations
translate.init({
  defaultLang: LANG_CODE,
  translations: i18n,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
    N7BoilerplateCommonModule.forRoot({}),
    N7BoilerplateMurucaModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (
      localConfigService: LocalConfigService,
      jsonConfigService: JsonConfigService,
      menuService: MrMenuService,
      footerService: MrFooterService,
      translationsLoader: MrTranslationsLoaderService
    ) => () => (
      localConfigService.load(configMuruca)
        .then(() => jsonConfigService.load(JSON_PATH))
        .then(() => Promise.all([
          menuService.load(),
          footerService.load(),
          translationsLoader.load(LANG_CODE)
        ]))
    ),
    deps: [
      LocalConfigService,
      JsonConfigService,
      MrMenuService,
      MrFooterService,
      MrTranslationsLoaderService
    ],
    multi: true
  }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private mainState: MainStateService,
    private config: ConfigurationService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: any) => {
        const { url } = event;
        this.mainState.updateCustom('currentNav', url);
      });

    // body classes
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => event.state.root.firstChild.data)
      )
      .subscribe((routeData: any) => {
        const { configId } = routeData || {};
        let bodyClasses = '';
        if (configId) {
          const pageConfig = this.config.get(configId) || {};
          bodyClasses = pageConfig.bodyClasses || '';
        }
        document.body.className = bodyClasses;
      });
  }
}
