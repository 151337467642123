import { MrSearchConfig } from '@net7/boilerplate-muruca';

const facets = {
  sections: [{
    id: 'section-query',
    inputs: [{
      id: 'query',
      type: 'text',
      queryParam: true,
      delay: 500,
      schema: {
        valueType: 'string'
      },
      data: {
        id: 'query',
        placeholder: 'search#placeholder_query',
        icon: 'n7-icon-search',
        inputPayload: 'search-input',
        enterPayload: 'search-enter',
        iconPayload: 'search-icon'
      }
    }]
  },
  {
    id: 'section-projects',
    header: {
      id: 'header-projects',
      data: {
        text: 'search#header_projects',
        additionalText: null
      }
    },
    inputs: [{
      id: 'projects',
      type: 'link',
      limit: 50,
      queryParam: true,
      schema: {
        valueType: 'string',
        multiple: true
      },
      data: {
        links: []
      }
    }]
  },
  {
    id: 'section-authors',
    header: {
      id: 'header-authors',
      data: {
        text: 'search#header_authors',
        additionalText: null
      }
    },
    inputs: [{
      id: 'authors',
      type: 'link',
      limit: 50,
      queryParam: true,
      schema: {
        valueType: 'string',
        multiple: true
      },
      data: {
        links: []
      }
    }]
  },
  {
    id: 'section-subjects',
    header: {
      id: 'header-subjects',
      data: {
        text: 'search#header_subjects',
        additionalText: null
      }
    },
    inputs: [{
      id: 'subjects',
      type: 'link',
      limit: 50,
      queryParam: true,
      schema: {
        valueType: 'string',
        multiple: true
      },
      data: {
        links: []
      }
    }]
  },
  {
    id: 'section-romances',
    header: {
      id: 'header-romances',
      data: {
        text: 'search#header_romances',
        additionalText: null,
        iconRight: 'n7-icon-angle-down',
      }
    },
    inputs: [{
      id: 'romances',
      type: 'link',
      limit: 50,
      queryParam: true,
      schema: {
        valueType: 'string',
        multiple: true
      },
      data: {
        links: []
      }
    }]
  },
  // {
  //   id: 'section-sources',
  //   header: {
  //     id: 'header-sources',
  //     data: {
  //       text: 'search#header_sources',
  //       additionalText: null
  //     }
  //   },
  //   inputs: [{
  //     id: 'sources',
  //     type: 'link',
  //     limit: 50,
  //     queryParam: true,
  //     schema: {
  //       valueType: 'string',
  //       multiple: true
  //     },
  //     data: {
  //       links: []
  //     }
  //   }]
  // },
  ],
  classes: 'facets-wrapper'
};

const layoutInputs = ['page', 'limit', 'sort'].map((id) => ({
  id,
  queryParam: true,
  schema: {
    valueType: id === 'sort' ? 'string' : 'number'
  }
}));

const request = {
  results: {
    id: 'search',
    delay: 500
  },
  facets: {
    id: 'facets',
  },
  provider: 'rest',
  delay: 500
};

export default { request, facets, layoutInputs } as MrSearchConfig;
