export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Obra',
  type: 'work',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null
      },
      {
        id: 'editor_metadata',
        type: 'metadata',
        grid: null
      }
    ],
    content: [
      {
        id: 'image-viewer',
        type: 'viewer',
        grid: 3,
        tools: true,
        title: 'resource#image-viewer',
      },
      {
        id: 'metadata',
        type: 'metadata',
        options: {
          readmore: {
            height: 250,
            labels: {
              more: 'readmore#more',
              less: 'readmore#less'
            }
          }
        }
      },
      {
        id: 'related_records',
        type: 'collection',
        grid: 3,
        title: 'resource#related_records',
      },
    ]
  }

};
