export default {
  defaultProvider: 'rest-local',
  providers: {
    rest: {
      type: 'rest',
      baseUrl: '//jsonplaceholder.typicode.com/',
      config: {
        sections: 'sections',
        search: 'posts',
        links: 'todos'
      }
    },
    'rest-local': {
      type: 'rest',
      // baseUrl: 'https://teatrocaballeresco-sls.netseven.it/',
      baseUrl: 'https://mwd.mc-metamotore.muruca.cloud/',
      config: {
        home: 'get_home',
        menu: 'get_menu',
        static: 'get_static/',
        post: 'get_static_post/',
        search: 'search/results',
        advancedSearch: 'advanced_search',
        advancedSearchOptions: 'advanced_search_options',
        searchDescription: 'get_search_description/',
        facets: 'search/facets',
        resource: 'get_resource',
        footer: 'get_footer',
        timeline: 'get_timeline/time-events',
        timelineDescription: 'get_search_description/timeline',
        translation: 'get_translation/'
      }
    }
  }
};
